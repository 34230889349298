import { MutableRefObject, useEffect, useMemo } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormResetField,
  useForm,
  useWatch
} from 'react-hook-form';

import Input from '../Input/Input';
import { inputLabels } from '../../data/labels';
import useData from '../../hooks/useData';
import { fetchOffices, fetchUsers } from '../../requests';
import { constructOfficeOptions } from '../../utils/common';
import { MezzaLog } from '../../types/types';
import { InputType, MezzaActivity, MezzaState } from '../../types/enums';
import { mezzaActivityTextByActivity, mezzaStatusTextByState } from '../../utils/mezza';

import styles from './MezzaLogFilterBlock.module.scss';

interface MezzaLogFilterBlockProps {
  logs: MezzaLog[];
  onChange: (logs: MezzaLog[]) => void;
  resetFilters: MutableRefObject<(() => void) | undefined>;
}

const MezzaLogFilterBlock = ({ logs, onChange, resetFilters }: MezzaLogFilterBlockProps) => {
  const form = useForm({
    mode: 'onSubmit',
    defaultValues: MEZZA_LOG_FILTER_BLOCK_DEFAULT_VALUES
  });

  useEffect(() => {
    resetFilters.current = form.reset;
  }, [form.reset]);

  const [officeId, userId, activity, date, mezza]: [string, string, string, string, string] =
    useWatch({
      name: [
        MezzaLogFilterBlockField.OFFICE,
        MezzaLogFilterBlockField.USER,
        MezzaLogFilterBlockField.ACTIVITY,
        MezzaLogFilterBlockField.DATE,
        MezzaLogFilterBlockField.MEZZA
      ],
      control: form.control
    });

  useEffect(() => {
    onChange(
      [
        { value: officeId, filterFunc: filterByOfficeId },
        { value: userId, filterFunc: filterByUserId },
        { value: activity, filterFunc: filterByActivity },
        { value: date, filterFunc: filterByDate },
        { value: mezza, filterFunc: filterByMezzaId }
      ].reduce((res, { value, filterFunc }) => {
        if (value) {
          return res.filter(filterFunc(value));
        }

        return res;
      }, logs)
    );
  }, [officeId, userId, activity, date, logs, mezza, onChange]);

  return (
    <div className={styles.wrapper}>
      <MezzaSelector logs={logs} {...form} />
      <OfficeSelector logs={logs} {...form} />
      <UserSelector logs={logs} {...form} />
      <ActivitySelector logs={logs} {...form} />
      <DateSelector logs={logs} {...form} />
    </div>
  );
};

const filterByOfficeId = (officeId: string) => (log: MezzaLog) =>
  log.actorOfficeId === Number(officeId);
const filterByUserId = (userId: string) => (log: MezzaLog) =>
  Number(log.user_id) === Number(userId);
const filterByActivity = (activity: string) => (log: MezzaLog) => log.activity === activity.split('|')[0] && (activity.split('|')[1] ? log.state === activity.split('|')[1]: true);
const filterByDate = (date: string) => (log: MezzaLog) => log.created_at.split('T')[0] === date;
const filterByMezzaId = (id: string) => (log: MezzaLog) => log.mezza_id === Number(id);

export interface MezzaLogFilterBlockFieldProps {
  control: Control<MezzaLogFilterBlockValues>;
  resetField: UseFormResetField<MezzaLogFilterBlockValues>;
  formState: FormState<MezzaLogFilterBlockValues>;
  logs: MezzaLog[];
}

export enum MezzaLogFilterBlockField {
  MEZZA = 'mezza',
  OFFICE = 'office',
  USER = 'user',
  ACTIVITY = 'activity',
  DATE = 'date'
}

const MEZZA_LOG_FILTER_BLOCK_DEFAULT_VALUES = {
  [MezzaLogFilterBlockField.MEZZA]: '',
  [MezzaLogFilterBlockField.OFFICE]: '',
  [MezzaLogFilterBlockField.USER]: '',
  [MezzaLogFilterBlockField.ACTIVITY]: '',
  [MezzaLogFilterBlockField.DATE]: ''
};

export type MezzaLogFilterBlockValues = {
  [MezzaLogFilterBlockField.MEZZA]: string;
  [MezzaLogFilterBlockField.OFFICE]: string;
  [MezzaLogFilterBlockField.USER]: string;
  [MezzaLogFilterBlockField.ACTIVITY]: string;
  [MezzaLogFilterBlockField.DATE]: string;
};

const DEFAULT_OPTION = { key: '', value: 'Mind' };

const OfficeSelector = ({ control }: MezzaLogFilterBlockFieldProps) => {
  const [offices] = useData(fetchOffices, 'Hiba történt az irodák lekérése során.');
  const officeOptions = useMemo(
    () => [DEFAULT_OPTION, ...constructOfficeOptions(offices)],
    [offices]
  );

  return (
    <Controller
      control={control}
      name={MezzaLogFilterBlockField.OFFICE}
      render={({ field }) => (
        <Input
          id={MezzaLogFilterBlockField.OFFICE}
          value={(officeOptions.find(({ key }) => key === field.value) ?? DEFAULT_OPTION).value}
          setValue={field.onChange}
          label={inputLabels.office}
          options={officeOptions}
        />
      )}
    />
  );
};

const UserSelector = ({ control }: MezzaLogFilterBlockFieldProps) => {
  const [users] = useData(fetchUsers, 'Hiba történt a felhasználók lekérdezése során.');
  const officeId = useWatch({ name: MezzaLogFilterBlockField.OFFICE, control });
  const usersByOffice = useMemo(() => {
    if (officeId) {
      return users.filter((it) => it.officeId === Number(officeId));
    }

    return users;
  }, [users, officeId]);
  const userOptions = useMemo(
    () =>
      usersByOffice
        .map((u) => ({
          key: u.id.toString(),
          value: u.name
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    [usersByOffice]
  );

  return (
    <Controller
      control={control}
      name={MezzaLogFilterBlockField.USER}
      render={({ field }) => (
        <Input
          id={MezzaLogFilterBlockField.USER}
          value={(userOptions.find(({ key }) => key === field.value) ?? DEFAULT_OPTION).value}
          setValue={field.onChange}
          label={inputLabels.user}
          options={[DEFAULT_OPTION, ...userOptions]}
        />
      )}
    />
  );
};

const ACTIVITY_OPTIONS = [
  { key: '', value: 'Mind' },
  {
    key: MezzaActivity.SENT_FOR_APPROVAL,
    value: mezzaActivityTextByActivity[MezzaActivity.SENT_FOR_APPROVAL]
  },
  {
    key: MezzaActivity.STATE_UPDATE+'|'+MezzaActivity.REJECTED,
    value: mezzaActivityTextByActivity[MezzaActivity.REJECTED]
  },
  {
    key: MezzaActivity.SEND,
    value: mezzaActivityTextByActivity[MezzaActivity.SEND]
  },
  {
    key: MezzaActivity.ASSIGN,
    value: mezzaActivityTextByActivity[MezzaActivity.ASSIGN]
  },
  {
    key: MezzaActivity.STATE_UPDATE,
    value: mezzaActivityTextByActivity[MezzaActivity.STATE_UPDATE]
  },
  {
    key: MezzaActivity.MESSAGE,
    value: mezzaActivityTextByActivity[MezzaActivity.MESSAGE]
  },
  {
    key: MezzaActivity.DATA_UPDATE,
    value: mezzaActivityTextByActivity[MezzaActivity.DATA_UPDATE]
  }
];
const ActivitySelector = ({ control }: MezzaLogFilterBlockFieldProps) => {
  return (
    <Controller
      control={control}
      name={MezzaLogFilterBlockField.ACTIVITY}
      render={({ field }) => (
        <Input
          id={MezzaLogFilterBlockField.ACTIVITY}
          value={(ACTIVITY_OPTIONS.find(({ key }) => key === field.value) ?? DEFAULT_OPTION).value}
          setValue={field.onChange}
          label={inputLabels.activity}
          options={ACTIVITY_OPTIONS}
        />
      )}
    />
  );
};

const DateSelector = ({ control }: MezzaLogFilterBlockFieldProps) => {
  return (
    <Controller
      control={control}
      name={MezzaLogFilterBlockField.DATE}
      render={({ field }) => (
        <Input
          type={InputType.DATE}
          id={MezzaLogFilterBlockField.DATE}
          value={field.value}
          setValue={field.onChange}
          label={inputLabels.date}
        />
      )}
    />
  );
};

const MezzaSelector = ({ control, logs }: MezzaLogFilterBlockFieldProps) => {
  const options = useMemo(() => {
    const ids = [...new Set(logs.map((it) => String(it.mezza_id)))];

    return [
      DEFAULT_OPTION,
      ...ids.map((it) => ({
        key: it,
        value: it
      }))
    ].sort((a, b) => +b.value - +a.value);
  }, [logs]);

  return (
    <Controller
      control={control}
      name={MezzaLogFilterBlockField.MEZZA}
      render={({ field }) => (
        <Input
          id={MezzaLogFilterBlockField.MEZZA}
          value={(options.find(({ key }) => key === field.value) ?? DEFAULT_OPTION).value}
          setValue={field.onChange}
          label={inputLabels.mezza}
          options={options}
        />
      )}
    />
  );
};

export default MezzaLogFilterBlock;
