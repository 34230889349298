import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../components/Layout/Layout';
import AdminSidebar from '../components/Sidebar/AdminSidebar';
import PaginatedTable from '../components/Table/PaginatedTable';
import TableControllers from '../components/Table/TableControllers';
import { DEFAULT_VISIBLE_ROW_COUNT } from '../data/constants';
import { buttonLabels, helperLabels, titles } from '../data/labels';
import { AREAS_TABLE_COLUMNS_CONFIG } from '../data/tableDefs';
import useFilter from '../hooks/useFilter';
import { fetchAreas } from '../requests';
import { AppRoute, ButtonType, SnackbarType } from '../types/enums';
import { Area } from '../types/types';
import Button from '../components/Button/Button';
import MapEditor from '../components/MapEditor/MapEditor';
import { NotificationContext } from '../App';

const Areas = () => {
  const navigate = useNavigate();
  const notify = useContext(NotificationContext);
  const [areas, setAreas] = useState<Area[]>([]);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_VISIBLE_ROW_COUNT);
  const [query, setQuery] = useState('');
  const filteredOffices = useFilter(areas, query);
  const [showMapEditor, setShowMapEditor] = useState(false);

  const refresh = useCallback(() => {
    fetchAreas().then((res) => {
      setAreas(res.data.items);
    })
    .catch(() => {
      notify('Hiba történt a Területek letöltése során, kérjük próbálja később.', SnackbarType.ERROR);
    })
  }, [notify]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const goToProperties = (area: Area) => {
    navigate(AppRoute.ADMIN_AREAS + '/' + area.id);
  };

  const openMapEditor = () => {
    if(areas.length) {
      setShowMapEditor(true);
      document.body.style.overflow = "hidden";
    }
  }
  const closeMapEditor = () => {
    setShowMapEditor(false);
    document.body.style.overflow = "visible";
    setAreas([]);
    refresh();
  }

  return (
    <>
      {showMapEditor && areas.length>0 && (
        <MapEditor areas={areas} closeMapEditor={closeMapEditor}/>
      )}
      {!showMapEditor && (
        <AdminSidebar />
      )}
      <Layout>
        <h1>{titles.manageAreas}</h1>
        <p className="mb-5">
          Ebben a menüpontban lehetősége van a területek adatainak keresésére és módosítására.
        </p>
        <div className="mb-25">
          <Button action={openMapEditor} type={ButtonType.SECONDARY} disabled={!(areas.length)}>
            {buttonLabels.editMap}
          </Button>
        </div>

        <TableControllers
          searchTitle={helperLabels.searchInAreas}
          onQueryChange={setQuery}
          onItemsPerPageChanged={setPageSize}
        />
        <PaginatedTable
          rowsPerPage={pageSize}
          data={filteredOffices}
          columns={AREAS_TABLE_COLUMNS_CONFIG}
          actions={[
            {
              title: buttonLabels.edit,
              minWidth: 140,
              width: 15,
              actionHandler: goToProperties
            }
          ]}
          defaultSortColumn="code"
        />
      </Layout>
    </>
  );
};

export default Areas;
