import { useEffect, useState } from 'react';

import { MezzaFormField, MezzaFormValues } from './MezzaForm';
import { MapData } from '../../types/types';
import { checkMezzaOfficeAddress } from '../../requests';

type AddressValidationProps = MezzaFormValues & {
  setCheckAddress: (state: number) => void;
  setChecked: (state: boolean) => void;
  initialId: number | undefined;
  settlements: MapData['settlements'];
  counties: MapData['counties'];
  districts: MapData['districts'];
};

const AddressValidation = ({
  setCheckAddress,
  setChecked,
  initialId,
  settlements,
  counties,
  districts,
  zip,
  settlement,
  street,
  streetType,
  county,
  houseNumber,
  district,
  officeId
}: AddressValidationProps) => {
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [warningColor, setWarningColor] = useState('#d1192e');

  useEffect(() => {
    setShowWarning(false);
    setWarningMessage('');

    if ([zip, settlement, street, streetType, county].every(Boolean)) {
      const locality = settlements.find(({ id }) => String(id) === settlement)?.name ?? '';
      const countyName = counties.find(({ id }) => String(id) === county)?.name ?? '';

      const cityCheck = countyName === 'Budapest' ? countyName + " " + zip.substring(1,3) : districts.find(({ id }) => +id === Number(district))
        ?.name ?? '';

      setCheckAddress(0);

      if(
        String(process.env.REACT_APP_MEZZA_APPROVER_OFFICE_IDS)
          .split(',')
          .includes(String(officeId))
        ) {
        setWarningColor('#1b7b2b');
        setShowWarning(true);
        setWarningMessage('Biztos, hogy a Gruppo T.F.M-nek szeretnéd küldeni a mezzát?');
        setCheckAddress(1);
        setChecked(true);
      } else {
        setWarningColor('#d1192e');
        validateAddress(zip, locality, [`${street} ${streetType}`, countyName, 'Magyarország']).then(
          (res) => {
            setShowWarning(res);
            //setWarningMessage('A megadott cím pontatlan lehet, vagy nem létezik, biztos, hogy elmented?');
            setWarningMessage('A megadott cím pontatlan lehet, vagy nem létezik, ezért a Mezza engedélyezést igényel! Elküldöd a Gruppo T.F.M.-nek engedélyezésre?');

            if(!res) {

              if(!initialId) {
                checkMezzaOfficeAddress(cityCheck, street, streetType, houseNumber).then((res) => {
                  if (Number(officeId) !== Number(res.data)) {
                    setCheckAddress(1);
                    setShowWarning(true);
                    setWarningMessage('A megadott cím alapján a Mezza engedélyezést igényel! Elküldöd a Gruppo T.F.M.-nek engedélyezésre?');
                  }
                });
              }

            } else {
              setCheckAddress(1);
            }

            setChecked(true);
          }
        );
      }

    }
  }, [zip, settlement, street, streetType, county, counties, settlements, houseNumber, district]);

  if (showWarning) {
    return (
      <div className="mb-25" style={{ color: warningColor, fontSize: '1.25rem' }}>
        {warningMessage}
      </div>
    );
  } else {
  }

  return <div>Biztosan el akarja küldeni a mezzát?</div>;
};


const validateAddress = async (zip: string, locality: string, addressLines: string[]) => {
  try {
    const response = await fetch(
      `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
      {
        method: 'POST',
        body: JSON.stringify({
          address: {
            regionCode: 'HU',
            postalCode: zip,
            locality,
            addressLines
          }
        })
      }
    );
    const { result } = await response.json();

    if (
      result?.verdict?.hasUnconfirmedComponents ||
      result?.address?.addressComponents?.some(({ spellCorrected }: { spellCorrected?: boolean }) =>
        Boolean(spellCorrected)
      )
    ) {
      return true;
    }

    return false;
  } catch (e) {
    console.error(e);

    return true;
  }
};

export default AddressValidation;
