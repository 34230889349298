import React, { useCallback, useContext, useState } from 'react';
import { GoogleMap, DrawingManager, LoadScript, Polygon } from '@react-google-maps/api';

import styles from './MapEditor.module.scss';
import { Area, EditedArea } from '../../types/types';
import { AreaStatus, ButtonType, SnackbarType } from '../../types/enums';
import { convertPointsToLatLng } from '../../utils/common';
import {COMMON_POLYGON_OPTIONS, POLYGON_OPTIONS_BY_STATUS} from '../Map/Map';
import Button from '../Button/Button';
import deleteIcon from './map-delete.svg';
import databaseIcon from './database-server.svg';
import { updateMapAreas } from '../../requests';
import { NotificationContext } from '../../App';

interface MapEditorProps {
  areas: Area[];
  closeMapEditor: () => void;
}

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 47.503,
  lng: 19.067
};

const MapEditor = ({ areas, closeMapEditor }: MapEditorProps) => {
  const notify = useContext(NotificationContext);
  const [drawingManagerConfig, setDrawingManagerConfig] = useState({});
  const [transformedAreas, setTransformedAreas] = useState<EditedArea[]>(() =>
    areas.map(formatArea)
  );
  const [editableAreas, setEditableAreas] = useState<Record<string, boolean>>({});
  const [deletedAreaIds, setDeletedAreaIds] = useState<{ id: number; new: boolean }[]>([]);
  const polygonRefs = React.useRef<Record<string, google.maps.Polygon>>({});
  const threshold = 0.0002; // Snap distance

  const closeEditor = () => {
    closeMapEditor();
  };

  const onSuccess = useCallback(() => {
    notify(
      'A térkép mentése sikeres volt',
      SnackbarType.SUCCESS
    );
    closeEditor();
  }, [notify]);

  const onError = useCallback(() => {
    notify(
      'A térkép mentése nem sikerült.',
      SnackbarType.ERROR
    );
  }, [notify]);

  const saveAreas = useCallback(() => {
    updateMapAreas({ areas: transformedAreas, deletedAreas: deletedAreaIds }, onSuccess, onError).then();
  }, [transformedAreas, deletedAreaIds]);

  const toggleEditable = (areaId: string) => {
    setEditableAreas((prev) => ({
      ...prev,
      [areaId]: !prev[areaId]
    }));
  };

  const snapVertex = (
    movedPoint: google.maps.LatLngLiteral,
    movingPolygonId: string
  ): google.maps.LatLngLiteral | null => {
    let closestPoint: google.maps.LatLngLiteral | null = null;
    let minDistance = threshold;

    Object.entries(polygonRefs.current).forEach(([id, polygon]) => {
      if (id === movingPolygonId) return; // Skip the moving polygon

      const path = polygon.getPath();
      path.forEach((point) => {
        const otherPoint: google.maps.LatLngLiteral = { lat: point.lat(), lng: point.lng() };
        const distance = getDistance(movedPoint, otherPoint);

        if (distance < minDistance) {
          minDistance = distance;
          closestPoint = otherPoint;
        }
      });
    });

    return closestPoint;
  };

  const addPathListeners = (polygon: google.maps.Polygon, areaId: string) => {
    const path = polygon.getPath();
    let skipEvent = false;

    const handleVertexChange = (index: number) => {
      if (skipEvent) return;

      const movedPoint = path.getAt(index).toJSON() as google.maps.LatLngLiteral;
      const snapPoint = snapVertex(movedPoint, areaId);

      if (snapPoint) {
        skipEvent = true;
        path.setAt(index, new google.maps.LatLng(snapPoint.lat, snapPoint.lng));
        skipEvent = false;
      }

      // Get updated path as geojson
      const updatedGeojson = path.getArray().map(point => (
        [
          point.lat(),
          point.lng()
        ]
      ));

      // Update transformedAreas with new path and mark as modified
      setTransformedAreas((prev) =>
        prev.map((area) =>
          area.id.toString() === areaId
            ? { ...area, storedGeoJson: JSON.stringify(updatedGeojson), modified: true }
            : area
        )
      );
    };


    path.addListener('set_at', (index: number) => handleVertexChange(index));
    path.addListener('insert_at', (index: number) => handleVertexChange(index));
  };

  const completeNewArea = (polygon: google.maps.Polygon) => {
    const snappedPath = polygon
      .getPath()
      .getArray()
      .map((point) => {
        const snappedPoint = snapVertex(point.toJSON(), 'new');
        return snappedPoint ? new google.maps.LatLng(snappedPoint.lat, snappedPoint.lng) : point;
      });

    polygon.setPath(snappedPath);

    setTimeout(() => {
      const areaCode = prompt('Az új terület kódja (pl. "BPXY1"):');
      if (!areaCode) {
        polygon.setMap(null); // Remove the polygon if no code is provided
        return;
      }

      const storedGeoJson = polygon.getPath().getArray().map(point => (
        [
          point.lat(),
          point.lng()
        ]
      ));

      const newArea: EditedArea = {
        id: Date.now(), // Use a unique ID
        code: areaCode,
        status: AreaStatus.FREE,
        geojson: snappedPath.map((point) => ({ lat: point.lat(), lng: point.lng() })),
        storedGeoJson: JSON.stringify(storedGeoJson),
        new: true,
        modified: true
      };

      setTransformedAreas((prev) => [...prev, newArea]);
      setEditableAreas((prev) => ({ ...prev, [newArea.id]: true }));

      polygon.setMap(null); // Remove the initial drawn polygon
    }, 100);
  };

  const deleteArea = (areaId: number) => {
    const areaToDelete = transformedAreas.find(area => area.id === areaId);
    if (window.confirm('Egészen biztosan törölhető ez a terület? (' + areaToDelete?.code + ')')) {
      setTransformedAreas((prev) => prev.filter((area) => area.id !== areaId));
      setDeletedAreaIds((prev) => [...prev, { id: areaId, new: areaToDelete?.new || false }]);

      if (polygonRefs.current[areaId]) {
        polygonRefs.current[areaId].setMap(null);
        delete polygonRefs.current[areaId];
      }
    }
  };

  const mapLoad = () => {
    setDrawingManagerConfig({
      polygonOptions: getAreaOptions(AreaStatus.FREE, true),
      drawingControl: true,
      drawingControlOptions: {
        position: window.google?.maps?.ControlPosition?.TOP_CENTER,
        drawingModes: [window.google?.maps?.drawing?.OverlayType?.POLYGON]
      }
    });
  };

  return (
    <div className={styles.mapEditor}>
      <div className={styles.mapWrapper}>
        <div className={styles.mapCanvas} id="map-canvas" />
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY ?? ''} libraries={['drawing']}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={14}
            clickableIcons={false}
            options={{ gestureHandling: 'greedy' }}
          >
            <DrawingManager onPolygonComplete={completeNewArea} options={drawingManagerConfig} onLoad={mapLoad} />
            {transformedAreas.map((area) => {
              if(area.geojson[0].lat !== 0) {
                return (
                  <Polygon
                    key={area.id}
                    onLoad={(polygon) => {
                      polygonRefs.current[area.id] = polygon;
                      addPathListeners(polygon, area.id.toString());
                    }}
                    onClick={() => toggleEditable(area.id.toString())}
                    paths={area.geojson}
                    options={getAreaOptions(area.status, editableAreas[area.id] ?? false)}
                  />
                )
              }
            })}
          </GoogleMap>
        </LoadScript>
      </div>
      <div className={styles.controlPanel}>
        <div className={styles.panelTitle}>TERÜLETEK</div>
        <div className={styles.listWrapper}>
          {transformedAreas.map((area) => {
            if(area.geojson[0].lat !== 0) {
              return(
                <div className={styles.areaLink} key={area.id}>
                  <span onClick={() => toggleEditable(area.id.toString())}>
                    {editableAreas[area.id] ? <strong>{area.code}</strong> : area.code}
                  </span>
                  {area.modified ? <img src={databaseIcon} className={styles.databaseIcon} alt={"*"} /> : null}
                  <img src={deleteIcon} onClick={() => deleteArea(area.id)} className={styles.deleteButton} alt={"X"} />
                </div>
              )
            }
          })}
        </div>
        <div className={styles.saveButton}>
          <Button action={saveAreas} type={ButtonType.PRIMARY}>Mentés</Button>
        </div>
      </div>
      <div className={styles.closePanel}>
        <Button action={closeEditor} type={ButtonType.SECONDARY}>Bezárás</Button>
      </div>
    </div>
  );
};

const formatArea = (area: Area): EditedArea => ({
  ...area,
  geojson: convertPointsToLatLng(JSON.parse(area.geojson)),
  storedGeoJson: '',
  new: false,
  modified: false,
  office_id: undefined,
  numOfEstates: undefined,
  office: undefined
});

const getAreaOptions = (status: AreaStatus, active: boolean) => ({
  ...COMMON_POLYGON_OPTIONS,
  ...POLYGON_OPTIONS_BY_STATUS[status],
  editable: active,
  //suppressUndo: true
});

const getDistance = (p1: google.maps.LatLngLiteral, p2: google.maps.LatLngLiteral) => {
  return Math.sqrt((p1.lat - p2.lat) ** 2 + (p1.lng - p2.lng) ** 2);
};

export default React.memo(MapEditor);
