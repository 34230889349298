import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm, Control, UseFormResetField, FormState, UseFormSetValue } from 'react-hook-form';

import { NotificationContext, UserContext } from '../../App';
import { titles, buttonLabels } from '../../data/labels';
import { fetchOffices, createMezza, updateMezza } from '../../requests';
import { ButtonType, SnackbarType } from '../../types/enums';
import Popup from '../Popup/Popup';
import { replaceEmptyStringFieldValueWithNull } from '../../utils/common';
import { Mezza } from '../../types/types';
import {
  Type,
  Category,
  SubType,
  County,
  Zip,
  Settlement,
  District,
  Street,
  StreetType,
  HouseNumber,
  ClientName,
  ClientPhone,
  ClientEmail,
  Office,
  Note,
  Building,
  Stairs,
  Floor,
  Door
} from './inputs';
import useMapData from '../../hooks/useMapData';
import useData from '../../hooks/useData';
import MezzaOverview from './MezzaOverview';

import styles from './MezzaForm.module.scss';

interface MezzaFormProps {
  onExit: () => void;
  refresh: () => void;
  initialMezza?: Mezza;
}

const MezzaForm = ({ onExit, refresh, initialMezza }: MezzaFormProps) => {

  const [needGruppoCheck, setNeedGruppoCheck] = useState(0);

  const notify = useContext(NotificationContext);
  const mapData = useMapData();
  const [offices] = useData(
    fetchOffices,
    'Hiba történt az Irodák letöltése során, kérjük próbálja később.'
  );
  const [isInProgress, setIsInProgress] = useState(true);

  const form = useForm({
    mode: 'onSubmit',
    defaultValues: MEZZA_FORM_DEFAULT_VALUES
  });

  const onSuccess = useCallback(() => {
    notify(
      initialMezza ? 'A mezza frissítése sikeres volt.' : 'Az új mezza sikeresen létrejött.',
      SnackbarType.SUCCESS
    );
    refresh();
    onExit();
  }, [initialMezza, notify, onExit, refresh]);

  const onError = useCallback(() => {
    notify(
      initialMezza ? 'A mezza frissítése nem sikerült.' : 'Az új mezza létehozása nem sikerült.',
      SnackbarType.ERROR
    );
  }, [initialMezza, notify]);

  const onSave = useCallback(() => {
    const apiCall = initialMezza ? updateMezza(initialMezza.id) : createMezza;

    apiCall(replaceEmptyStringFieldValueWithNull(form.getValues()), onSuccess, onError, needGruppoCheck);
  }, [form, onError, onSuccess, initialMezza, needGruppoCheck]);

  const setCheckAddress = (state: number) => {
    setNeedGruppoCheck(state);
  }

  useEffect(() => {
    if (initialMezza) {
      mapMezzaToForm(initialMezza, form.setValue);
    }
  }, [initialMezza, form.setValue, needGruppoCheck]);

  if (isInProgress) {
    return (
      <Popup
        show
        title={initialMezza ? titles.editMezza : titles.createMezza}
        onHide={onExit}
        footerButtons={[
          {
            title: buttonLabels.cancel,
            type: ButtonType.PRIMARY,
            action: onExit,
            inverse: true
          },
          {
            title: initialMezza ? buttonLabels.mezzaSave : buttonLabels.mezzaSend,
            type: ButtonType.PRIMARY,
            action: form.handleSubmit(() => {
              setIsInProgress(false);
              form.reset(form.getValues());
            })
          }
        ]}>
        <div className={styles.grid}>
          <Category {...form} />
          <Type {...form} />
          <SubType {...form} />
          <County {...form} counties={mapData.counties} />
          <Zip {...form} />
          <Settlement {...form} settlements={mapData.settlements} />
          <District {...form} districts={mapData.districts} />
          <Street {...form} />
          <StreetType {...form} />
          <HouseNumber {...form} />
          <Building {...form} />
          <Stairs {...form} />
          <Floor {...form} />
          <Door {...form} />
          <ClientName {...form} />
          <ClientPhone {...form} />
          <ClientEmail {...form} />
          <Office offices={offices} {...form} fromOfficeId={initialMezza?.sender_office?.id} />
        </div>
        <Note {...form} />
      </Popup>
    );
  }

  return (
    <MezzaOverview
      values={form.getValues()}
      onSave={onSave}
      mapData={mapData}
      offices={offices}
      onBack={() => setIsInProgress(true)}
      onExit={onExit}
      setCheckAddress={setCheckAddress}
      initialId={initialMezza?.id}
    />
  );
};

export interface MezzaFormFieldProps {
  control: Control<MezzaFormValues>;
  resetField: UseFormResetField<MezzaFormValues>;
  formState: FormState<MezzaFormValues>;
}

export enum MezzaFormField {
  CATEGORY = 'category',
  TYPE = 'type',
  SUB_TYPE = 'subType',
  COUNTY = 'county',
  ZIP = 'zip',
  SETTLEMENT = 'settlement',
  DISTRICT = 'district',
  STREET = 'street',
  STREET_TYPE = 'streetType',
  HOUSE_NUMBER = 'houseNumber',
  CLIENT_NAME = 'clientName',
  CLIENT_PHONE = 'clientPhone',
  CLIENT_EMAIL = 'clientEmail',
  TO_OFFICE_ID = 'officeId',
  NOTE = 'note',
  BUILDING = 'building',
  STAIRS = 'stairs',
  FLOOR = 'floor',
  DOOR = 'door'
}

const MEZZA_FORM_DEFAULT_VALUES = {
  [MezzaFormField.CATEGORY]: '',
  [MezzaFormField.TYPE]: '',
  [MezzaFormField.SUB_TYPE]: '',
  [MezzaFormField.COUNTY]: '',
  [MezzaFormField.ZIP]: '',
  [MezzaFormField.SETTLEMENT]: '',
  [MezzaFormField.DISTRICT]: '',
  [MezzaFormField.STREET]: '',
  [MezzaFormField.STREET_TYPE]: '',
  [MezzaFormField.HOUSE_NUMBER]: '',
  [MezzaFormField.CLIENT_NAME]: '',
  [MezzaFormField.CLIENT_PHONE]: '',
  [MezzaFormField.CLIENT_EMAIL]: '',
  [MezzaFormField.TO_OFFICE_ID]: '',
  [MezzaFormField.NOTE]: '',
  [MezzaFormField.BUILDING]: '',
  [MezzaFormField.STAIRS]: '',
  [MezzaFormField.FLOOR]: '',
  [MezzaFormField.DOOR]: ''
};

export type MezzaFormValues = {
  [MezzaFormField.CATEGORY]: string;
  [MezzaFormField.TYPE]: string;
  [MezzaFormField.SUB_TYPE]: string;
  [MezzaFormField.COUNTY]: string;
  [MezzaFormField.ZIP]: string;
  [MezzaFormField.SETTLEMENT]: string;
  [MezzaFormField.DISTRICT]: string;
  [MezzaFormField.STREET]: string;
  [MezzaFormField.STREET_TYPE]: string;
  [MezzaFormField.HOUSE_NUMBER]: string;
  [MezzaFormField.CLIENT_NAME]: string;
  [MezzaFormField.CLIENT_PHONE]: string;
  [MezzaFormField.CLIENT_EMAIL]: string;
  [MezzaFormField.TO_OFFICE_ID]: string;
  [MezzaFormField.NOTE]: string;
  [MezzaFormField.BUILDING]: string;
  [MezzaFormField.STAIRS]: string;
  [MezzaFormField.FLOOR]: string;
  [MezzaFormField.DOOR]: string;
};

const mapMezzaToForm = (mezza: Mezza, setValue: UseFormSetValue<MezzaFormValues>) => {
  Object.entries({
    [MezzaFormField.CATEGORY]: mezza.category,
    [MezzaFormField.TYPE]: mezza.type,
    [MezzaFormField.SUB_TYPE]: mezza.sub_type,
    [MezzaFormField.COUNTY]: mezza.county_id,
    [MezzaFormField.ZIP]: mezza.zip,
    [MezzaFormField.SETTLEMENT]: mezza.settlement_id,
    [MezzaFormField.DISTRICT]: mezza.district_id,
    [MezzaFormField.STREET]: mezza.street,
    [MezzaFormField.STREET_TYPE]: mezza.street_type,
    [MezzaFormField.HOUSE_NUMBER]: mezza.house_number,
    [MezzaFormField.CLIENT_NAME]: mezza.client_name,
    [MezzaFormField.CLIENT_PHONE]: mezza.client_phone,
    [MezzaFormField.CLIENT_EMAIL]: mezza.client_email,
    [MezzaFormField.TO_OFFICE_ID]: mezza.recipient_office?.id,
    [MezzaFormField.NOTE]: mezza.note,
    [MezzaFormField.BUILDING]: mezza.building,
    [MezzaFormField.STAIRS]: mezza.stairs,
    [MezzaFormField.FLOOR]: mezza.floor,
    [MezzaFormField.DOOR]: mezza.door
  }).forEach(([key, value]) => {
    setValue(key as MezzaFormField, value ? String(value) : '');
  });
};

export default MezzaForm;
