import { useCallback, useContext, useState } from 'react';

import { Mezza, User } from '../../types/types';
import { ButtonType, MezzaState, SnackbarType } from '../../types/enums';
import { mezzaStatusTextByState } from '../../utils/mezza';
import Input from '../Input/Input';
import { hasMezzaApproveAccess, hasMezzaStatusEditAccess, isAdmin } from '../../utils/auth';
import { NotificationContext, UserContext } from '../../App';
import { buttonLabels } from '../../data/labels';
import Popup from '../Popup/Popup';
import TextArea from '../TextArea/TextArea';
import { fetchOffices, updateApprovedMezzaState, updateMezzaState } from '../../requests';

import styles from './MezzaApproveUpdater.module.scss';
import useData from '../../hooks/useData';

interface MezzaApproveUpdaterProps {
  mezza: Mezza;
  onChange: () => void;
  hide: () => void;
}

const initialErrors = { note: false, state: false };

const MezzaApproveUpdater = ({ mezza, onChange, hide }: MezzaApproveUpdaterProps) => {
  const { user } = useContext(UserContext);
  const notify = useContext(NotificationContext);
  const [newState, setNewState] = useState(mezza.state);
  const [newOffice, setNewOffice] = useState(String(mezza.recipient_office?.id));
  const [note, setNote] = useState('');
  const [errors, setErrors] = useState(initialErrors);

  const states = availableNextStates(mezza.state);
  const options = states.map((it) => ({
    key: it,
    value: mezzaStatusTextByState[it]
  }));
  const [offices] = useData(
    fetchOffices,
    'Hiba történt az Irodák letöltése során, kérjük próbálja később.'
  );
  const formOffices = offices.map((it) => ({
    key: String(it.id),
    value: it.name
  }));

  const onStatusUpdate = useCallback(() => {
    const newErrors = { ...initialErrors };
    if (newState === mezza.state) {
      newErrors.state = true;
    }
    if (!note) {
      newErrors.note = true;
    }
    setErrors(newErrors);

    if (newErrors.note || newErrors.state) {
      return;
    }

    updateApprovedMezzaState(mezza.id, newState, note, newOffice)
      .then(() => {
        notify('A státusz sikeresen módosítva.', SnackbarType.SUCCESS);
        onChange();
        hide();
      })
      .catch(() => {
        notify('Hiba történt a mezza státuszának módosítása során.', SnackbarType.ERROR);
      });
  }, [mezza, notify, onChange, newState, note, newOffice, hide]);

  return (
    <Popup
      show
      title="Státusz módosítása (jóváhagyás/elutasítás)"
      footerButtons={[
        {
          title: buttonLabels.cancel,
          type: ButtonType.PRIMARY,
          action: hide,
          inverse: true
        },
        {
          title: buttonLabels.changeStatus,
          type: ButtonType.PRIMARY,
          action: onStatusUpdate
        }
      ]}
      onHide={hide}
    >
      <div className={styles.popup}>
        <p>Biztosan módosítani kívánja a(z) {mezza.id} mezza státuszát? </p>
        <Input
          options={options}
          setValue={(val) => setNewState(val as MezzaState)}
          value={mezzaStatusTextByState[newState]}
          id="state"
          label="Státusz"
          disabled={!hasMezzaApproveAccess(user as User)}
          error={errors.state ? 'A státusz nem változott!' : ''}
        />

        <Input
          options={formOffices}
          setValue={(val) => setNewOffice(val)}
          value={typeof formOffices.find((item) => item.key === newOffice)?.value !== 'undefined' ? String(formOffices.find((item) => item.key === newOffice)?.value) : String(mezza.recipient_office?.name)}
          id="to_office"
          label="Címzett iroda"
        />

        <TextArea
          id="note"
          label="Üzenet"
          value={note}
          setValue={setNote}
          error={errors.note ? 'Kötelező mező!' : ''}
        />
      </div>
    </Popup>
  );
};

const availableNextStates = (state: MezzaState) => {
  switch (state) {
    case MezzaState.NEED_APPROVAL:
      return [MezzaState.NOT_TAKEN_OVER, MezzaState.REJECTED];
    default:
      return [];
  }
};

export default MezzaApproveUpdater;
