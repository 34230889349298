import { ButtonType } from '../../types/enums';

import styles from './Button.module.scss';
import { useState } from 'react';

export interface ButtonProps {
  type: ButtonType;
  children: React.ReactNode;
  action: () => void;
  inverse?: boolean;
  disabled?: boolean
}

const Button = ({ type, inverse = false, children, action, disabled = false }: ButtonProps) => {

  const [isClicked, setIsClicked] = useState(false);
  const btnStyle = inverse ? styleMapping[type].inverse : styleMapping[type].normal;

  const clickMe = () => {
    if(!isClicked) {
      setIsClicked(true);
      action();
      setTimeout(() => {
        setIsClicked(false);
      }, 1000);
    }
  }

  return (
    <button onClick={clickMe} className={btnStyle} disabled={disabled}>
      {children}
    </button>
  );
};

const styleMapping = {
  [ButtonType.PRIMARY]: {
    normal: styles.primary,
    inverse: styles.primaryInverse
  },
  [ButtonType.SECONDARY]: {
    normal: styles.secondary,
    inverse: styles.secondaryInverse
  }
};

export default Button;
