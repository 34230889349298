import { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { titles } from '../../data/labels';
import { MezzaState, ButtonType, MezzaActivity, SnackbarType } from '../../types/enums';
import { Mezza, User } from '../../types/types';
import { hasMezzaStatusEditAccess, hasMezzaAssignAccess, isAdmin, hasMezzaApproveAccess } from '../../utils/auth';
import Button from '../Button/Button';
import MezzaActivityCard from '../MezzaActivityCard/MezzaActivityCard';
import MezzaAssign from '../MezzaAssign/MezzaAssign';
import MezzaStateUpdater from '../MezzaStateUpdater/MezzaStateUpdater';
import MezzaApproveUpdater from '../MezzaApproveUpdater/MezzaApproveUpdater';
import TabTitle from '../TabTitle/TabTitle';
import { mezzaStatusTextByState } from '../../utils/mezza';
import { UserContext, NotificationContext } from '../../App';
import { fetchMezza } from '../../requests';
import MezzaDetails from '../MezzaDetails/MezzaDetails';
import NewMessage from '../NewMessage/NewMessage';
import MezzaForm from '../MezzaForm/MezzaForm';

import styles from './MezzaDetailLayout.module.scss';
import MezzaStatus from '../MezzaStatus/MezzaStatus';

enum Tab {
  ALL = 'ALL',
  STATE = 'STATE',
  MESSAGE = 'MESSAGE'
}

interface MezzaDetailLayoutProps {
  id: string;
  messageSetup?: ReactElement;
}

const MezzaDetailLayout = ({ id, messageSetup = <></> }: MezzaDetailLayoutProps) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const notify = useContext(NotificationContext);
  const [mezza, setMezza] = useState<Mezza>();
  const [showAllocatePopup, setShowAllocatePopup] = useState(false);
  const [activeTab, setActiveTab] = useState(Tab.ALL);
  const [showNewMessage, setShowNewMessage] = useState(false);
  const [showStateEditor, setShowStateEditor] = useState(false);
  const [showApproveEditor, setShowApproveEditor] = useState(false);
  const [showEditor, setShowEditor] = useState(false);

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const hideNewMessage = useCallback(() => setShowNewMessage(false), []);

  const activityList = useMemo(() => {
    if (mezza) {
      switch (activeTab) {
        case Tab.MESSAGE:
          return (mezza.mezza_log_filtered ?? []).filter(
            ({ activity }) => activity === MezzaActivity.MESSAGE
          );
        case Tab.STATE:
          return (mezza.mezza_log_filtered ?? []).filter(
            ({ activity }) => activity === MezzaActivity.STATE_UPDATE
          );
        default:
          return mezza.mezza_log_filtered ?? [];
      }
    }

    return [];
  }, [mezza, activeTab]);

  const refresh = useCallback(() => {
    fetchMezza(id)
      .then((res) => {
        setMezza(res.data);
      })
      .catch(() => {
        notify('Hiba történt a mezza lekérése során!', SnackbarType.ERROR);
      });
  }, [notify, id]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <>
      <div className={styles.header}>
        <div>
          <h1>{titles.mezzaInfo}</h1>
          <p className="mb-25">
            Ebben a menüpontban láthatja a mezza rendszerben megosztott bejegyzéseket.
          </p>
        </div>
        <div className={styles.buttonGroup}>
          <Button type={ButtonType.SECONDARY} inverse action={goBack}>
            Vissza
          </Button>
          <div className={styles.messageButtons}>
            {messageSetup}
            <Button type={ButtonType.SECONDARY} action={() => setShowNewMessage(true)}>
              Új üzenet
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.flex}>
        <div className={styles.leftPanel}>
          <TabTitle>Adatlap</TabTitle>
        </div>
        <div className={styles.rightPanel}>
          <TabTitle>
            <div className={styles.tabNames}>
              {Object.entries(tabs).map(([key, title]) => (
                <div
                  key={key}
                  className={activeTab === key ? styles.activeTab : ''}
                  onClick={() => setActiveTab(key as Tab)}
                >
                  {title}
                </div>
              ))}
            </div>
          </TabTitle>
        </div>
      </div>
      <div className={styles.flex}>
        <div className={styles.leftPanel}>
          {mezza && (
            <>
              {hasMezzaApproveAccess(user as User) && (
                <>
                  {showApproveEditor && (
                    <MezzaApproveUpdater
                      mezza={mezza}
                      onChange={refresh}
                      hide={() => setShowApproveEditor(false)}
                    />
                  )}
                </>
              )}
              {showStateEditor && (
                <MezzaStateUpdater
                  mezza={mezza}
                  onChange={refresh}
                  hide={() => setShowStateEditor(false)}
                />
              )}
              <div className="mb-25">
                <MezzaDetails
                  stateOnTop
                  mezza={mezza}
                  renderStateEditorButton={() =>
                    (hasMezzaStatusEditAccess(user as User, mezza)) ? (
                      <div className="mb-25">
                        <Button action={() => setShowStateEditor(true)} type={ButtonType.SECONDARY}>
                          Státusz módosítása
                        </Button>
                      </div>
                    ) : null
                  }
                  renderApproveButton={() =>
                    null
                  }
                />
              </div>
              <div className={styles.mezzaActions}>
                {(isAdmin(user as User)) && (
                  <>
                    <Button type={ButtonType.SECONDARY} action={() => setShowEditor(true)}>
                      Adatlap szerkesztése
                    </Button>
                    {showEditor && (
                      <MezzaForm
                        refresh={refresh}
                        onExit={() => setShowEditor(false)}
                        initialMezza={mezza}
                      />
                    )}
                  </>
                )}
                {hasMezzaAssignAccess(user as User, mezza) && (
                  <Button action={() => setShowAllocatePopup(true)} type={ButtonType.SECONDARY}>
                    MEZZA hozzárendelése
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
        <div className={styles.activity}>
          <div>
            {activityList.length > 0 ? (
              activityList.map((log) => {
                const data = JSON.parse(log.data);

                return (
                  <MezzaActivityCard
                    activity={log.activity}
                    actor={data?.isSystemUpdate ? 'Rendszer' : log.userName}
                    dateTime={log.created_at}
                    key={log.id}
                  >
                    {log.activity === MezzaActivity.STATE_UPDATE ? (
                      <StateUpdateCard note={data.note} state={data.state} />
                    ) : (
                      data.message
                    )}
                  </MezzaActivityCard>
                );
              })
            ) : (
              <p>Ehhez a mezzához még nem tartozik aktivitás.</p>
            )}
          </div>
        </div>
      </div>
      {showAllocatePopup && mezza && (
        <MezzaAssign
          hide={() => setShowAllocatePopup(false)}
          onChanged={() => {
            refresh();
            setShowAllocatePopup(false);
          }}
          mezza={mezza}
        />
      )}
      {showNewMessage && <NewMessage id={id} onSuccess={refresh} hide={hideNewMessage} />}
    </>
  );
};

const tabs: Record<Tab, string> = {
  [Tab.ALL]: 'Aktivitás',
  [Tab.MESSAGE]: 'Üzenetek',
  [Tab.STATE]: 'Státuszok'
};

const StateUpdateCard = ({ note, state }: { note: string; state: MezzaState }) => (
  <>
    <span>
      A mezza státusza megváltozott. Az új státusz:&nbsp;
      <strong>{mezzaStatusTextByState[state]}</strong>
    </span>
    <strong>Üzenet:</strong>
    <div>{note}</div>
  </>
);

export default MezzaDetailLayout;
