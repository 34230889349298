import { useNavigate, useParams } from 'react-router-dom';

import MezzaSidebar from '../../components/Sidebar/MezzaSidebar';
import Layout from '../../components/Layout/Layout';
import MezzaDetailLayout from '../../components/MezzaDetailLayout/MezzaDetailLayout';
import MezzaMessageSetup from '../../components/MezzaMessageSetup/MezzaMessageSetup';
import MezzaApproveDetailLayout from '../../components/MezzaDetailLayout/MezzaApproveDetailLayout';

const MezzaApproveDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) {
    navigate(-1);

    return null;
  }

  return (
    <>
      <MezzaSidebar />
      <Layout>
        <MezzaApproveDetailLayout id={id} messageSetup={<MezzaMessageSetup id={id} />} />
      </Layout>
    </>
  );
};

export default MezzaApproveDetails;
